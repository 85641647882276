
import { CommonRouteNames } from '@/router/route-names'
import { defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import ModuleHeader from '@/components/commons/boxes/ModuleHeader.vue'
import { useRoute, useRouter } from 'vue-router'
import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'
import { StaffTracingAPI } from '@/api/staffTracing.api'
import EmailSending from '@/components/modals/EmailSending.vue'
import AddRegister from '@/components/modals/AddRegister.vue'
import { SetMail } from '@/models/email-sending/SetMail'

export default defineComponent({
  components: {
    ModuleHeader,
    EmailSending,
    AddRegister
  },
  setup () {
    const isLoading = ref(false)
    const router = useRouter()
    const { params: { teacherId } } = useRoute()
    const { query: { isReinfo } } = useRoute()
    const errorRef = ref<null|unknown>(null)
    const teacherActivity = ref<StaffTracing[]>([])
    const emailModal = ref<boolean>(false)
    const registerModal = ref<boolean>(false)
    const SelectedTracing = ref<any[]>([])
    const emailInfo = ref<SetMail>()
    const tracingEmail = ref<StaffTracing>()
    onBeforeMount(() => {
      isLoading.value = true
    })

    const showEmailModal = () => {
      emailModal.value = !emailModal.value
    }
    const showRegisterlModal = () => {
      registerModal.value = !registerModal.value
    }
    onMounted(async () => {
      try {
        teacherActivity.value = await StaffTracingAPI.getTeacherActivity(teacherId as string)
        emailInfo.value = {
          userId: teacherActivity.value[0].userInfo._id,
          country: teacherActivity.value[0].userInfo.profile.country,
          toMail: teacherActivity.value[0].userInfo.email
        }
        tracingEmail.value = teacherActivity.value[0]
      } catch (error) {
        errorRef.value = error
        router.push({
          name: CommonRouteNames.ERROR_SCREEN,
          params: {
            error: JSON.stringify(error)
          }
        })
      } finally {
        isLoading.value = false
      }
    })
    return {
      isLoading,
      isReinfo,
      errorRef,
      teacherActivity,
      SelectedTracing,
      showEmailModal,
      showRegisterlModal,
      tracingEmail,
      emailModal,
      registerModal,
      emailInfo
    }
  }

})
